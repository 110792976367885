.App
{
	text-align: center;
}


.g-header
{
	color: red !important;
	font-size: 24px;
	font-weight: 700;
	text-align: center;
}


.speed-button-wrapper
{
	background: white;
	border-radius: 50%;
	box-shadow: 0 0 10px 0 lightgray;
	color: white;
	display: inline-block;
	height: 60px;
	margin-left: 20px;
	margin-right: 20px;
	margin-top: -12px;
	text-align: center;
	width: 60px;
	vertical-align: top;
}


.speed-button
{
    font-size: 38px;
    opacity: 1;
    cursor: pointer;
    color: #FA5E46;
}


.click-button-wrapper
{
	background: white;
	border-radius: 50%;
	box-shadow: 0 0 10px 0 lightgray;
	color: white;
	display: inline-block;
	height: 80px;
	margin-top: 24px;
	text-align: center;
	width: 80px;
	vertical-align: top;
}


.click-button
{
    font-size: 38px;
    opacity: 1;
    cursor: pointer;
    color: #FA5E46;
}


.click-button > svg
{
	margin-left: 2px;
	margin-top: 20px;
}


.metronome-panel
{
	background: #F5F5F5;
}


.custom-input-group
{
	background: white;
	border: 1px solid lightgray;
	font-size: 17px;
	margin-bottom: 10px;
	margin-left: 0px;
	margin-right: 0px;
	padding: 10px 15px 10px 15px;
}


.half-width
{
	width: 50%;
}


.metronome-number
{
	color: #FA5E46;
	font-family: "Roboto", sans-serif;
	font-size: 60px;
	font-weight: 900;
	margin-bottom: 0;
}


.metronome-reference
{
	color: #232C32;
	font-family: "Roboto", sans-serif;
	font-size: 20px;
	font-weight: 600;
	margin-top: -6px;
}


/* Footer */
footer.hello-footer
{
	background: #FBFBFD;
	border: 1px solid #F5F5F5;
	bottom: 0;
	padding: 25px 0;
	text-align: center;
	position: fixed;
	width: 100%;
}


footer.hello-footer span.footer-copyright
{
	font-size: 18px;
	font-weight: 500;
	line-height: 40px;
	text-transform: none;
}


ul.footer-icon
{
	list-style-type: none;
	margin: 0;
	overflow: hidden;
	padding: 0;
	text-align: center;
}

ul.footer-icon li {
	float: right;
}


ul.footer-icon li a
{
	background-color: red;
	border-radius: 100%;
	color: white;
	font-size: 20px;
	display: block;
	height: 40px;
	line-height: 40px;
	margin-right: 20px;
	outline: none;
	width: 40px;
}


ul.footer-icon li a:hover,
ul.footer-icon li a:focus,
ul.footer-icon li a:active
{
	background-color: black;
}


@media screen and (max-width: 1024px)
{
	footer.hello-footer
	{
		background: #FBFBFD;
		border: 1px solid #F5F5F5;
		bottom: 0;
		padding: 25px 0;
		text-align: center;
		position: static;
		width: 100%;
	}
}


@media screen and (max-width: 667px)
{
	.speed-button-wrapper
	{
		margin-left: 10px;
		margin-right: 10px;
	}
}


input[type=range]
{
	-webkit-appearance: none;
	margin: 13.2px 0;
	width: 40%;
}


input[type=range]:focus
{
	outline: none;
}


input[type=range]::-webkit-slider-runnable-track
{
	background: #928483;
	border: 1px solid #562425;
	border-radius: 6px;
	box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0D0D0D;
	height: 12px;
	cursor: pointer;
	width: 100%;
}


input[type=range]::-webkit-slider-thumb
{
	-webkit-appearance: none;
	background: #6B1817;
	border: 2px solid #000000;
	border-radius: 2px;
	box-shadow: 1px 1px 1px #050000, 0px 0px 1px #1F0000;
	cursor: pointer;
	height: 39px;
	margin-top: -14px;
	width: 19px;
}


input[type=range]:focus::-webkit-slider-runnable-track
{
	background: #9E9191;
}


input[type=range]::-moz-range-track
{
	background: #928483;
	border: 0.7px solid #562425;
	border-radius: 6px;
	cursor: pointer;
	box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0D0D0D;
	height: 12.6px;
	width: 100%;
}


input[type=range]::-moz-range-thumb
{
	background: #6B1817;
	border: 2px solid #000000;
	border-radius: 2px;
	box-shadow: 1px 1px 1px #050000, 0px 0px 1px #1F0000;
	cursor: pointer;
	height: 39px;
	width: 19px;
}


input[type=range]::-ms-track
{
	background: transparent;
	border-color: transparent;
	color: transparent;
	cursor: pointer;
	height: 12px;
	width: 100%;
}


input[type=range]::-ms-fill-lower
{
	background: #867776;
	border: 1px solid #562425;
	border-radius: 12px;
	box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0D0D0D;
}


input[type=range]::-ms-fill-upper
{
	background: #928483;
	border: 0.7px solid #562425;
	border-radius: 12px;
	box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0D0D0D;
}


input[type=range]::-ms-thumb
{
	background: #6B1817;
	border: 2px solid #000000;
	border-radius: 2px;
	box-shadow: 1px 1px 1px #050000, 0px 0px 1px #1F0000;
	height: 39px;
	cursor: pointer;
	height: 12px;
	width: 19px;
}


input[type=range]:focus::-ms-fill-lower
{
	background: #928483;
}


input[type=range]:focus::-ms-fill-upper
{
	background: #9E9191;
}
